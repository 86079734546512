<template>
  <div class="planCont">
    <div class="planTab">
      <span :class="{ activity: tab == 1 }" @click="changeTab(1)"
        ><i>{{ $t('Index.本地服務計劃 （學生及18-29歲年青人限定）') }}</i></span
      >
      <span :class="{ activity: tab == 2 }" @click="changeTab(2)"
        ><i>{{ $t('Index.一咭兩地服務計劃（18-29歲年青人限定）') }}</i></span
      >
    </div>
    <div class="planBox">
      <div class="item" v-show="tab == 1">
        <div class="tag">
          <div>
            <img :src="$imgs['home/84.png']" alt="" />
            <img class="pc_activity" :src="$imgs['home/84_activity.png']" alt="" />
          </div>
          <span>{{ $t('Index.全城最划算') }}</span>
        </div>
        <div class="content">
          <div class="price">
            <div class="left">
              <div class="t">
                <span>{{ $t('Index.起') }}</span>
                <div class="h1Div">$98</div>
                <span>{{ $t('Index.起/月') }}</span>
              </div>
              <div class="old">{{ $t('Index.原价') }}$338</div>
            </div>
            <img :src="$imgs['home/s.png']" alt="" />
          </div>
          <div class="detail">
            <div class="tit">
              <div class="h1Div">30GB</div>
              <div class="h3Div">{{ $t('Index.其后1Mbps高速任用') }}</div>
            </div>
            <div class="blue">{{ $t('Index.送2GB中国内地、澳门漫游数据(+)') }}<sup>+</sup></div>
            <ul>
              <li class="bg">{{ $t('Index.18-29岁免5个月月费') }}</li>
              <img :src="$imgs['home/appList.png']" alt="" />
              <li>{{ $t('Index.攜號轉台免$18/月行政費') }}</li>
              <li>12/24{{ $t('Index.个月合约期') }}</li>
            </ul>
          </div>
          <div class="button" @click="goUrl(`https://www.hk.chinamobile.com/${locale}/home/plan/slash`)">
            {{ $t('Index.了解更多') }}
          </div>
          <!-- <div class="button hidden-sm-and-up" @click="goUrl(`${env.VITE_APP_SLASH_OPEN}/servicePlan?lang=${locale}`)">
            {{ is5Gservice_planPage ? $t('5Gservice_plan.立即登记') : $t('Index.了解更多') }}
          </div> -->
        </div>
      </div>
      <div class="item" v-show="tab == 1">
        <div class="tag">
          <div>
            <img :src="$imgs['home/124.png']" alt="" />
            <img class="pc_activity" :src="$imgs['home/124_activity.png']" alt="" />
          </div>
          <span>{{ $t('Index.CP值最高') }}</span>
        </div>
        <div class="content">
          <div class="price">
            <div class="left">
              <div class="t">
                <span>{{ $t('Index.起') }}</span>
                <div class="h1Div">$138</div>
                <span>{{ $t('Index.起/月') }}</span>
              </div>
              <div class="old">{{ $t('Index.原价') }}$388</div>
            </div>
            <img :src="$imgs['home/m.png']" alt="" />
          </div>
          <div class="detail">
            <div class="tit">
              <div class="h1Div">50GB</div>
              <div class="h3Div">{{ $t('Index.其后1Mbps高速任用') }}</div>
            </div>
            <div class="blue">{{ $t('Index.送4GB中国内地、澳门漫游数据(+)') }}<sup>+</sup></div>
            <ul>
              <li class="bg">{{ $t('Index.18-29岁免5个月月费') }}</li>
              <img :src="$imgs['home/appList.png']" alt="" />
              <li>{{ $t('Index.享$1,200 5G手機獎賞（24個月合約') }}</li>
              <li>{{ $t('Index.攜號轉台免$18/月行政費') }}</li>
              <li>12/24{{ $t('Index.个月合约期') }}</li>
            </ul>
          </div>
          <div class="button" @click="goUrl(`https://www.hk.chinamobile.com/${locale}/home/plan/slash`)">
            {{ $t('Index.了解更多') }}
          </div>
          <!-- <div class="button hidden-sm-and-up" @click="goUrl(`${env.VITE_APP_SLASH_OPEN}/servicePlan?lang=${locale}`)">
            {{ is5Gservice_planPage ? $t('5Gservice_plan.立即登记') : $t('Index.了解更多') }}
          </div> -->
        </div>
      </div>
      <div class="item" v-show="tab == 1">
        <div class="tag">
          <div>
            <img :src="$imgs['home/149.png']" alt="" />
            <img class="pc_activity" :src="$imgs['home/149_activity.png']" alt="" />
          </div>
          <span>{{ $t('Index.高用量之选') }}</span>
        </div>
        <div class="content">
          <div class="price">
            <div class="left">
              <div class="t">
                <span>{{ $t('Index.起') }}</span>
                <div class="h1Div">$178</div>
                <span>{{ $t('Index.起/月') }}</span>
              </div>
              <div class="old">{{ $t('Index.原价') }}$438</div>
            </div>
            <img :src="$imgs['home/l.png']" alt="" />
          </div>
          <div class="detail">
            <div class="tit">
              <div class="h1Div">100GB</div>
              <div class="h3Div">{{ $t('Index.其后5Mbps高速任用') }}</div>
            </div>
            <div class="blue">{{ $t('Index.送6GB中国内地、澳门漫游数据(+)') }}<sup>+</sup></div>
            <ul>
              <li class="bg">{{ $t('Index.18-29岁免5个月月费') }}</li>
              <img :src="$imgs['home/appList.png']" alt="" />
              <li>{{ $t('Index.享$1,200 5G手機獎賞（24個月合約') }}</li>
              <li>{{ $t('Index.攜號轉台免$18/月行政費') }}</li>
              <li>12/24{{ $t('Index.个月合约期') }}</li>
            </ul>
          </div>
          <div class="button" @click="goUrl(`https://www.hk.chinamobile.com/${locale}/home/plan/slash`)">
            {{ $t('Index.了解更多') }}
          </div>
          <!-- <div class="button hidden-sm-and-up" @click="goUrl(`${env.VITE_APP_SLASH_OPEN}/servicePlan?lang=${locale}`)">
            {{ is5Gservice_planPage ? $t('5Gservice_plan.立即登记') : $t('Index.了解更多') }}
          </div> -->
        </div>
      </div>
      <div class="item" v-show="tab == 2">
        <div class="tag">
          <div>
            <img :src="$imgs['home/124.png']" alt="" />
            <img class="pc_activity" :src="$imgs['home/124_activity.png']" alt="" />
          </div>
          <span>{{ $t('Index.北上必選') }}</span>
        </div>
        <div class="content">
          <div class="price">
            <div class="left">
              <div class="t">
                <span>{{ $t('Index.起') }}</span>
                <div class="h1Div">$139</div>
                <span>{{ $t('Index./月') }}</span>
              </div>
              <div class="old">{{ $t('Index.原价') }}$349</div>
            </div>
            <img class="changeWidth" :src="$imgs['home/plan_bg1.png']" alt="" />
          </div>
          <div class="detail">
            <div class="tit">
              <div class="h1Div">20GB</div>
              <div class="h3Div">{{ $t('Index.中國内地及香港共用數據 + 其後本地高達1Mbps') }}</div>
            </div>
            <p class="h4Div">{{ $t('Index.+ 其後本地高達1Mbps') }}</p>
            <div class="blue">{{ $t('Index.含200分鐘中國内地通話分鐘') }}</div>
            <ul>
              <li class="bg">{{ $t('Index.$9/月加購15個常用App本地數據任用權益（價值$38/月') }}</li>
              <img :src="$imgs['home/appList.png']" alt="" />
              <li>{{ $t('Index.送「中國內地號碼服務」或「關聯家鄉號」（二選一優惠）') }}</li>
              <li>{{ $t('Index.攜號轉台免$18/月行政費') }}</li>
              <li>24{{ $t('Index.个月合约期') }}</li>
            </ul>
          </div>
          <div
            class="button"
            @click="goUrl(`https://www.hk.chinamobile.com/${locale}/home/plan/detail?commodityId=21202407181813763863953936384`)">
            {{ $t('Index.了解更多') }}
          </div>
          <!-- <div class="button hidden-sm-and-up" @click="goUrl(`${env.VITE_APP_SLASH_OPEN}/servicePlan?lang=${locale}`)">
            {{ is5Gservice_planPage ? $t('5Gservice_plan.立即登记') : $t('Index.了解更多') }}
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { navigate } from 'vite-plugin-ssr/client/router'
import 'element-plus/theme-chalk/display.css'

let tab = ref(1)
function changeTab(n: number) {
  tab.value = n
}
const env = import.meta.env
const { locale } = useI18n()
function goRouter(name: string) {
  navigate('/' + locale.value + name)
}
function goUrl(url) {
  window.location.href = url
}
// const is5Gservice_planPage = ref(false)
// onMounted(() => {
//   // console.log(window.location.pathname)
//   if (window.location.pathname.includes('5Gservice_plan')) {
//     is5Gservice_planPage.value = true
//   }
// })
</script>
<style scoped lang="less">
.planCont {
  .planTab {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    span {
      white-space: pre-wrap;
      background: #f3faff;
      width: (500 / 19.2vw);
      padding: 18px 0;
      transform: skewX(-20deg);
      color: rgba(0, 167, 255, 0.6);
      display: block;
      cursor: pointer;
      i {
        display: block;
        font-weight: 600;
        font-size: (20/19.2vw);
        text-align: center;
        transform: skewX(20deg);
      }
      &.activity {
        background: linear-gradient(138deg, #00a7ff 0%, #00ceff 100%);
        color: #ffffff;
      }
    }
  }
}
.planBox {
  display: flex;
  justify-content: center;
  padding-bottom: 1.354vw;
  .item {
    width: 20.833vw;
    background: #ffffff;
    border-radius: 1.25vw;
    border: 2px solid #f3faff;
    position: relative;
    transform: translateY(0);
    transition: all 0.3s;
    padding-bottom: 80px;
    .tag {
      height: 3.75vw;
      display: flex;
      align-items: center;
      background: #f3faff;
      border-top-left-radius: 0.9vw;
      border-top-right-radius: 0.9vw;
      .pc_activity {
        display: none;
      }
      img {
        width: 1.25vw;
        height: 1.25vw;
        margin-right: 0.52vw;
        margin-left: 1.302vw;
      }
      span {
        font-size: 1.041vw;
        font-weight: 600;
        color: #00a7ff;
      }
    }
    .content {
      padding: 0 1.822vw;
      .price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 28px;
        border-bottom: 1px solid #00a7ff;
        margin-top: 1.458vw;
        position: relative;
        .left {
          .t {
            display: flex;
            align-items: baseline;
            .h1Div {
              font-size: 3.125vw;
              font-weight: 600;
              color: rgba(0, 0, 0, 0.8);
              padding-right: 0.52vw;
            }
            span {
              font-size: 0.833vw;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.8);
            }
          }
          .old {
            text-decoration: line-through;
            font-size: 0.833vw;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.4);
          }
        }
        > img {
          width: 5.208vw;
          height: 5.208vw;
          &.changeWidth {
            position: absolute;
            width: (180/19.2vw);
            height: auto;
            right: -5px;
            top: 10px;
          }
        }
      }
      .detail {
        margin-top: 28px;
        .tit {
          display: flex;
          align-items: center;
          justify-content: center;
          .h1Div {
            display: flex;
            align-items: center;
            background: #f0fc98;
            font-size: 1.458vw;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.8);
            padding: 0 0.2vw;
            margin-right: 0.4vw;
            span.delete {
              text-decoration: line-through;
              font-size: 0.375vw;
            }
          }
          .h3Div {
            display: block;
            font-size: 0.9375vw;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.8);
          }
        }
        .h4Div {
          font-size: 0.9375vw;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.8);
          text-align: center;
        }
        .blue {
          font-size: 0.9375vw;
          text-align: center;
          font-weight: 600;
          padding-top: 8px;
          color: #00a7ff;
        }
        ul {
          padding: 24px;
          img {
            width: 86%;
            margin: 0 auto 16px;
          }
          li {
            font-size: 0.7395vw;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.8);
            margin-bottom: 16px;
            position: relative;
            &.bg {
              list-style: none;
              &::before {
                position: absolute;
                content: '';
                left: -15px;
                top: 0px;
                margin: auto;
                width: 0.625vw;
                height: 1.094vw;
                background: url('@/assets/imgs/home/planLi.png') no-repeat center center;
                background-size: 100%;
              }
            }
          }
        }
      }
      .button {
        position: absolute;
        left: 0;
        margin: auto;
        right: 0;
        bottom: 2.343vw;
        width: 11.77vw;
        height: 2.708vw;
        line-height: 2.708vw;
        background: #f3faff;
        color: #00a7ff;
        font-size: 1.042vw;
        text-align: center;
        cursor: pointer;
        border-radius: 5px;
      }
    }
    &:nth-of-type(2) {
      margin: 0 2.916vw;
    }
    &:hover {
      transform: translateY(-20px);
      border-color: #eb6ea5;
      .tag {
        background: #eb6ea5;
        span {
          color: white;
        }
        > div {
          transform: translate(-1.042vw, -1.042vw);
          width: 3.75vw;
          height: 3.75vw;
          background: #f0fc98;
          box-shadow: 0px 2px 6px 1px rgba(0, 38, 62, 0.16);
          border-radius: 50%;
          display: flex;
          align-items: center;
          .pc_activity {
            display: block;
          }
          img {
            display: none;
            margin: 0 auto;
          }
        }
      }
      .content {
        .detail > ul > li.bg {
          &::before {
            position: absolute;
            left: -15px;
            top: 0px;
            margin: auto;
            width: 0.625vw;
            height: 1.094vw;
            content: '';
            background: url('@/assets/imgs/home/planLi-activity.png') no-repeat center center;
            background-size: 100%;
          }
        }
        > .button {
          color: white;
          background: linear-gradient(to bottom right, rgba(235, 110, 165, 1), rgba(255, 131, 207, 1));
        }
      }
    }
  }
}
.goBtn {
  padding: 44px 0 109px;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    max-width: 16.927vw;
    padding: 1.042vw 1.146vw 1.146vw 3.177vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to right, rgba(0, 167, 255, 1), rgba(0, 206, 255, 1));
    border-radius: 8px;
    cursor: pointer;
    span {
      text-align: center;
      margin-right: 18px;
      font-size: 1.042vw;
      font-weight: 600;
      color: #ffffff;
    }
  }
}
@media screen and (max-width: 768px) {
  @width: 375;
  .planCont {
    .planTab {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      span {
        white-space: pre-wrap;
        background: #f3faff;
        width: 166px;
        padding: 12px 5px;
        overflow: hidden;
        transform: skewX(-20deg);
        color: rgba(0, 167, 255, 0.6);
        display: block;
        cursor: pointer;
        i {
          display: block;
          font-weight: 600;
          font-size: 12px;
          text-align: center;
          transform: skewX(20deg);
          white-space: pre-wrap;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
        }
        &.activity {
          background: linear-gradient(138deg, #00a7ff 0%, #00ceff 100%);
          color: #ffffff;
        }
      }
    }
  }
  .planBox {
    display: block;
    .item {
      width: (342 / @width * 100%);
      border-radius: 16px;
      margin: 0 auto 16px;
      border: 2px solid #00a7ff;
      box-sizing: border-box;
      padding-bottom: 60px;
      &:hover {
        transform: translateY(0);
        border-color: #00a7ff;
        .tag {
          height: 44px;
          background: #00a7ff;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          div {
            transform: translate(-6px, -6px);
            width: 40px;
            height: 40px;
            background: #f0fc98;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            img.pc_activity {
              display: none;
            }
            img {
              display: block;
            }
          }
          span {
            font-size: 20px;
            color: #fff;
          }
        }
        .content {
          .detail > ul > li.bg {
            &::before {
              position: absolute;
              left: -15px;
              top: 0px;
              margin: auto;
              width: 0.625vw;
              height: 1.094vw;
              content: '';
              background: url('@/assets/imgs/home/planLi-activity.png') no-repeat center center;
              background-size: 100%;
            }
          }
          > .button {
            color: white;
            background: linear-gradient(to bottom right, #00a7ff, #00ceff);
          }
        }
      }
      &:nth-of-type(2) {
        margin: 0 auto 16px;
      }
      .tag {
        height: 44px;
        background: #00a7ff;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        div {
          transform: translate(-6px, -6px);
          width: 40px;
          height: 40px;
          background: #f0fc98;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 2px 6px 1px rgba(0, 38, 62, 0.16);
        }
        .pc_activity {
          display: none;
        }
        img {
          width: 24px;
          height: 24px;
          margin-right: 0;
          margin-left: 0;
        }
        span {
          flex: 1;
          font-size: 20px;
          color: #fff;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .content {
        padding: 0 18px;
        .button {
          margin: 0 auto;
          text-align: center;
          background: linear-gradient(to bottom right, #00a7ff, #00ceff);
          color: #fff;
          bottom: 32px;
          width: 192px;
          height: 44px;
          line-height: 44px;
          font-size: 16px;
          border-radius: 8px;
        }
        .price {
          margin-top: 4px;
          position: relative;
          img {
            width: 80px;
            height: 80px;
            &.changeWidth {
              position: absolute;
              width: 160px;
              height: auto;
              right: 0;
              top: 0;
            }
          }
          .left {
            .t {
              .h1Div {
                font-size: 48px;
              }
              span {
                font-size: 12px;
              }
            }

            .old {
              font-size: 12px;
            }
          }
        }
      }
      .content .detail .tit .h1Div {
        font-size: 24px;
        padding: 0 2px;
        margin-right: 5px;
      }
      .content .detail .tit .h3Div {
        font-size: 14px;
      }
      .content .detail .h4Div {
        font-size: 14px;
      }
      .content .detail .blue {
        font-size: 16px;
      }
      .content .detail ul {
        padding-top: 16px;
        li {
          font-size: 16px;
        }
        li.bg {
          font-size: 16px;
          padding-left: 0;
          &::before {
            width: 10px;
            height: 18px;
            background: url('@/assets/imgs/home/planLi-activity.png') no-repeat center center;
            background-size: 100%;
          }
        }
      }
    }
  }
}
</style>
